import React, { useState } from 'react';
import './Auth.css';

const Auth: React.FC<{ onLogin: (token: string) => void }> = ({ onLogin }) => {
    const [telegramId, setTelegramId] = useState('');
    const [password, setPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const url = isRegistering ? '/api/register' : '/api/login';
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ telegramId: telegramId, password }),
            });
            const data = await response.json();
            if (data.success) {
                onLogin(data.token);
            } else {
                setError(data.message || 'Ошибка авторизации');
            }
        } catch (err) {
            setError('Ошибка соединения с сервером');
        }
    };

    return (
        <div className="auth">
            <form onSubmit={handleSubmit}>
                <h2>{isRegistering ? 'Регистрация' : 'Авторизация'}</h2>
                <input
                    type="text"
                    placeholder="Telegram Id"
                    value={telegramId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTelegramId(e.target.value)}
                    required
                />
                {!isRegistering && <input
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    required
                />}
                <button type="submit" className={'submit-button'}>{isRegistering ? 'Зарегистрироваться' : 'Войти'}</button>
                <p>
                    {isRegistering ? 'Уже зарегистрированы?' : 'Нет аккаунта?'}
                    <span className={'switcher'} onClick={() => setIsRegistering(!isRegistering)}>
                        {isRegistering ? ' Войти' : ' Регистрация'}
                    </span>
                </p>
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    );
};

export default Auth;
