import React, { useState, useEffect } from 'react';
import './Chat.css';

const Chat: React.FC<{ token: string }> = ({ token }) => {
    const [messages, setMessages] = useState<{ role: string; content: string }[]>([]);
    const [input, setInput] = useState('');
    const [socket, setSocket] = useState<WebSocket | null>(null);

    useEffect(() => {
        const ws = new WebSocket('wss://darters.ru/chat');
        ws.onopen = () => {
            ws.send(JSON.stringify({ type: 'auth', token }));
        };
        ws.onmessage = (event: MessageEvent<any>) => {
            const data = JSON.parse(event.data);
            if (data.type === 'message') {
                setMessages((prev) => [...prev, { role: 'assistant', content: data.message }]);
            }
        };
        ws.onclose = () => console.log('WebSocket закрыт');
        setSocket(ws);

        return () => ws.close();
    }, [token]);

    const sendMessage = () => {
        if (socket && input.trim()) {
            setMessages((prev) => [...prev, { role: 'user', content: input }]);
            socket.send(JSON.stringify({ type: 'message', message: input }));
            setInput('');
        }
    };

    return (
        <div className="chat">
            <div className="messages">
                {messages.map((msg, idx) => (
                    <div key={idx} className={`message-${msg.role}`}>
                        {msg.content}
                    </div>
                ))}
            </div>
            <div className="input">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Напишите сообщение..."
                />
                <button onClick={sendMessage}>Отправить</button>
            </div>
        </div>
    );
};

export default Chat;
